import { IonAlert, IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonPage, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import "../theme/Results.css";
import { PIPEDB_URL } from '../common/pipedb-url';

import "../theme/Utilities.css";
import { useParams } from "react-router";
import { MeasurementResource, ObjectModel } from "../common/types";
import Measurements from "../components/Measurements";
import NameModal from "../components/NameModal";
import MeasurmentResult from "../components/MeasurementsResults";
import ResultsFeedBack from "../components/ResultsFeedback";
import Toolbar from "../components/Toolbar";
import Store from "../components/StoreModel";
import { closeOutline } from "ionicons/icons";
const ResultPage: React.FC = () => {
	const { id } = useParams<{ id: string }>()
	const [isFetched, setIsFetched] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [isFeedbackGiven, setIsFeedbackGiven] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [shouldClose, setShouldClose] = useState(false);
	const [objectModel, setObjectModel] = useState<ObjectModel>({
		id: "",
		createdAt: "",
		obj: "",
		visibleEffects: [0],
        showMeasurement: '',
		measurements: {
			id: "",
			createdAt: "",
			height: 0,
			head: 0,
			neck: 0,
			chest: 0,
			waist: 0,
			buttocks: 0,
			leg_inner: 0,
			arm_outer: 0
		}
	});
	const [measurements, setMeasurements] = useState<MeasurementResource>({
		id: "",
		createdAt: "",
		height: 0,
		head: 0,
		neck: 0,
		chest: 0,
		waist: 0,
		buttocks: 0,
		leg_inner: 0,
		arm_outer: 0,
	});
	const [localStorageIsFull, setLocalStorageIsFull] = useState(false)
	const [isOpen, setIsOpen] = useState(false);
	

	const fetchModel = async () => {
		await fetch(
			`${PIPEDB_URL}/task/${id}/model`
		)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				return res.json();
			})
			.then((data) => {
                
				console.log(data);
				setObjectModel(data);
				setIsFetched(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchMeasurements = async () => {
		
		const heightInput = await fetch(`${PIPEDB_URL}/task/${window.location.href.split("/")[4]}/height_input`, {
			method: 'GET'
		}).then(res => res.json()).then(data => data.value);
		await fetch(
			`${PIPEDB_URL}/task/${window.location.href.split("/")[4]
			}/measurements`
		)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				
				return res.json();
			})
			.then((data) => {
				const scale = heightInput*1000 / data.height;
				['height',
					'head',
					'neck',
					'chest',
					'waist',
					'buttocks',
					'leg_inner',
					'arm_outer'].forEach((key) => {
						data[key] = data[key] * scale;
					});			
				setMeasurements(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
    const onCloseFeedback = ()=> {
        if(shouldClose)
            window.location.replace("/")
    }
    const onClose = () => {
        setShouldClose(true)
        if(!isFeedbackGiven)
            {
                setIsFeedbackOpen(true)
            }
        else
            onCloseFeedback()
    
    }

	const saveModel = async (name: string) => {
		const result = {
			generatedId: id,
			name: name,
			createdAt: new Date().toLocaleString().replace(/(.*)\D\d+/, '$1'),
			objectModel: objectModel.obj,
			measurements: measurements
		};
        await fetch(`${PIPEDB_URL}/task/${id}/name`,  {
			method: 'POST',
			body: JSON.stringify({name: name, value: name})
		});
		Store().store(result).then(() => {
			console.log("SUCCESS")
            setIsSaved(true)
            if(!isFeedbackGiven)
                {setIsFeedbackOpen(true)}
            
		}).catch(async res => {
			console.log(res)
			if (res.message.includes("The quota has been exceeded")) {
				console.log("localStorage is full")
				setIsOpen(true)
			}
		})

	}

	useEffect(() => {
		// if new taskId, setIsfetched to false
		setIsFetched(false)
	}, [id])


	useEffect(() => {
		if (!isFetched) {
			const interval = setInterval(() => {
				fetchModel();
				fetchMeasurements();
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [isFetched]);

    const getMeasurementByName = (name: string) => {
        switch (name) {
            case "height":
                return Math.round(measurements.height / 10);
            case "head":
                return Math.round(measurements.head / 10);
            case "neck":
                return Math.round(measurements.neck / 10);
            case "chest":
                return Math.round(measurements.chest / 10);
            case "arm_outer":
                return Math.round(measurements.arm_outer / 10);
            case "waist":
                return Math.round(measurements.waist / 10);
            case "buttocks":
                return Math.round(measurements.buttocks / 10);
            case "leg_inner":
                return Math.round(measurements.leg_inner / 10);
            default:
                return 0;
        }
    }
	
			return <IonPage>
				<Toolbar subHeader= { isFetched?"Results":"Loading Results"}></Toolbar>
				{!isFetched?
                <IonContent>
					<div className="loadingIconText">
						<div className="loadingIcon">
							<IonSpinner  name="lines-sharp"></IonSpinner>
						</div>
						<br></br>
						<br></br>
						<IonText className="loadingText">
							<h1>Please wait...</h1>
						</IonText>
					</div>
				</IonContent>:
				<IonContent>
					<IonGrid className="resultContent">

                        <MeasurmentResult objectModel={objectModel} setObjectModel={setObjectModel} measurements={measurements} />
						<div className="resultBtn">
							<NameModal saveModel={saveModel} isSaved={isSaved}/>
                            <ResultsFeedBack onCloseFeedback={onCloseFeedback} isOpen={isFeedbackOpen} isFeedbackGiven={isFeedbackGiven} setIsFeedbackGiven={setIsFeedbackGiven} setIsOpen={(value: boolean)=>{
                                console.log("set", value)
                                setIsFeedbackOpen(value)}
                                } id={id} measurements={measurements} ></ResultsFeedBack>

							<IonButton fill="outline"  color="danger" onClick={onClose}>
								Close <IonIcon src={closeOutline}></IonIcon>
							</IonButton>
							<IonAlert
								isOpen={isOpen}
								header="Storage is full"
								subHeader="Error"
								message="The storage is full. Please delete an old measurement before saving the new one."
								buttons={['OK']}
								onDidDismiss={() => { setIsOpen(false); setLocalStorageIsFull(true) }}
							></IonAlert>

							<IonModal isOpen={localStorageIsFull}>
								<IonHeader>
									<IonToolbar>
										<IonTitle>Delete a measurement</IonTitle>
										<IonButtons slot="end">
											<IonButton  onClick={() => setLocalStorageIsFull(false)}>Close</IonButton>
										</IonButtons>
									</IonToolbar>
								</IonHeader>
								<Measurements />
							</IonModal>
						</div>
					</IonGrid>
				</IonContent>
}
			</IonPage >
};

export default ResultPage;
