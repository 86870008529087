import {
	IonButton,
	IonCard,
	IonCardContent,
	IonContent,
	IonIcon,
	IonImg,
	IonPage,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../MyContext";
import "../theme/PhotoPreview.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";
import { ImageResource, OrientResource } from "../common/types";
import { orientationTransform } from '../common/orientation-transform';
import { delay } from '../common/delay';
import { PIPEDB_URL } from '../common/pipedb-url';
import { CameraOverlay } from "../components/CameraOverlay";
import Toolbar from "../components/Toolbar";
import { chevronForwardOutline, refreshOutline } from "ionicons/icons";
const PhotoCapture: React.FC = () => {
	const { id, gender, pose } = useParams<{ id: string, gender:string, pose:string }>()

    const [uploaded , setUploaded] = useState(false);
	const { frontImgDone, frontMaskDone, setFrontMaskDone, setFrontImgDone } = useContext(Context);
	const [image, setImage] = useState<ImageResource>();
	const [objectMask, setObjectMask] = useState<ImageResource>();
	const [orientation, setOrientation] = useState<OrientResource>();
    const which = "ready";
    useEffect(()=>{
		setFrontImgDone(false)
		setFrontMaskDone(false)
	
        setUploaded(false)
    },[pose])

    
	const fetchImage = async () => {
		while (true) {
            await delay(1000);

			try {
				console.log("ID:", id);
				let response = await fetch(`${PIPEDB_URL}/task/${id}/${pose}_img`);
				setImage(await response.json());
				if (response.status === 200) return;
			} catch (error: any) {
				console.error(error);
			}
		}
	};

	const fetchMask = async () => {
		while (true) {
            await delay(1000);

			try {
				let response = await fetch(`${PIPEDB_URL}/task/${id}/${pose}_mask`);
				setObjectMask(await response.json());
				if (response.status === 200) return;
			} catch (error: any) {
				console.error(error);
			}
		}
	};

	const fetchOrientation = async () => {
		while (true) {
            await delay(1000);

            console.log("fetchOrientation")
			try {
				let response = await fetch(`${PIPEDB_URL}/task/${id}/${pose}_orient`);
				setOrientation(await response.json());
				if (response.status === 200) return;
			} catch (error: any) {
				console.error(error);
			}
		}
	};
    const optimizeImages = async () => {
		let response = await fetch(
			`${PIPEDB_URL}/task/${id}/${which}`,
			{
				method: "POST",
				body: JSON.stringify({ createdBy: "app-alpha1" }),
			}
		);
		await response.json();
	};

	const maskTransform = useMemo(() => {
		return orientationTransform(orientation?.orient);
	}, [orientation]);

	useEffect(() => {
        console.log("useEffect", frontImgDone, frontMaskDone, id, uploaded)
		const doAsync = async() => {
            console.log("doAsync")
			await Promise.all([fetchImage(), fetchMask(), fetchOrientation()]);
			setFrontMaskDone(true);
		};
		if (!frontImgDone && !frontMaskDone && uploaded) doAsync();
	}, [frontImgDone, frontMaskDone, id, uploaded]);

	return (
		<IonPage>
            {uploaded?
			<Toolbar subHeader={pose}></Toolbar>:''}
			{!uploaded?<IonContent fullscreen>
                <CameraOverlay id={id} pose={pose} gender={gender} setUploaded={setUploaded}/>

            </IonContent>:<IonContent fullscreen>
				{!frontMaskDone ? (
					<div className="loadingIconText">
						<div className="loadingIcon">
							<IonSpinner  name="lines-sharp"></IonSpinner>
						</div>
						<br></br>
						<br></br>
						<IonText className="loadingText">
							<h1>Please wait...</h1>
						</IonText>
					</div>
				) : (
					<><div className="previewImageContainer">
						<IonImg
							src={`data:image/png;base64,${image?.pixels}`}
							className="previewImage"
							style={{ width: 420, height: 420 }} />
						<div className="previewMaskOverlay"><IonImg
							src={`data:image/png;base64,${objectMask?.pixels}`}
							className="previewMask"
							style={{ transform: `${maskTransform}`, width: 420, height: 420 }} /></div>
					</div><IonCard>
							<IonCardContent>
								Does the silhouette match the image and are you happy with the
								picture? If not, please retake the picture.
							</IonCardContent>
						</IonCard><div className="buttons">
							<IonButton size="large" fill="outline" color={"warning"}  onClick={()=>{
                                setUploaded(false)
                                setFrontMaskDone(false)
                                setFrontImgDone(false)
                                
                                }}>
								Retake photo <IonIcon src={refreshOutline}></IonIcon>
							</IonButton>
                            
							{pose==='front'?<IonButton size="large" fill="outline"  routerLink={`/task/${id}/${gender}/right_img`}>
								Continue <IonIcon src={chevronForwardOutline}></IonIcon>
							</IonButton>:<IonButton size="large" fill="outline" onClick={() => optimizeImages()} routerLink={`/task/${id}/result`}>
								See results <IonIcon src={chevronForwardOutline}></IonIcon>
							</IonButton>
                            }
						</div></>
				)}
			</IonContent>}
		</IonPage>
	);
};

export default PhotoCapture;
