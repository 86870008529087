
import '../theme/Webcam.css';

import { ChangeEvent, ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PIPEDB_URL } from '../common/pipedb-url';
import overlay_f from "../assets/neutral_f_average.png";
import overlay_r from "../assets/neutral_r_average.png";
import overlay_f_male from "../assets/male_f_average.png";
import overlay_r_male from "../assets/male_r_average.png";
import overlay_f_female from "../assets/female_f_average.png";
import overlay_r_female from "../assets/female_r_average.png";
import { Camera, CameraType } from 'react-camera-pro'
import { IonIcon, IonSelect, IonSelectOption } from '@ionic/react';
import DeviceOrientation from '../components/DeviceOrientation';
import { Context } from '../MyContext';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';
import PoseEstimation, { VideoSize } from "../components/PoseEstimation"
import * as poseDetection from "@tensorflow-models/pose-detection";

const overlay_image: Record<string, string> = {
  "front_female": overlay_f_female,
  "right_female": overlay_r_female,
  "front_male": overlay_f_male,
  "right_male": overlay_r_male,
  "front_neutral": overlay_f,
  "right_neutral": overlay_r,
};
interface CameraOverlayProps {
  id: string;
  pose: string;
  gender: string;
  setUploaded: Function;

};

interface imageData {
  imageSrc?: string;
  width?: number;
  height?: number;
  size?: number;
  length?: number;
}

interface VideoPoseContainerProps {
  width: string | number;
  height: string | number;
};


const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  z-index: 1001;

  @media (max-aspect-ratio: 2/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const maxWidth = 768
const TakePhotoButton = styled(Button)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;
  
  @media (max-width: ${maxWidth}px) {
    &:disabled {
    background-color: rgba(128, 128, 128, 0.5);
    cursor: not-allowed;
    opacity: 0.5;
    }
  }

  &:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const UploadButton = styled(Button)`
  background: url('https://img.icons8.com/ios/50/upload-to-ftp.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  z-index: 1001;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const VideoPoseContainer = styled.div<VideoPoseContainerProps>`
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${props => props.height};
  min-width: ${props => props.width};
  
  @media (max-width: ${maxWidth}px) {
    

    #video, .pose-info #pose-canvas {
      
    }
  }
`;

export const CameraOverlay = (props: CameraOverlayProps) => {
  const fileInput = useRef(null);

  const { orientation, poses } = useContext(Context);
  const tolerance = 5;
  const [showHelpText, setShowHelpText] = useState(true);
  const [upArrowColor, setUpArrowColor] = useState('red');
  const [downArrowColor, setDownArrowColor] = useState('red');
  const [videoSize,setVideoSize] = useState<VideoSize>({height:0,width:0})
  const isCorrectOrientation = Math.abs(orientation.beta - 90) <= tolerance;
  

  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null);
  const cameraRef = useRef<HTMLDivElement>(null);

  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= maxWidth);

  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState<string | null>(null);
  const [showImage, setShowImage] = useState<boolean>(false);
  const camera = useRef<CameraType>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isCorrectOrientation) {
      setArrowColors("green");
      setShowHelpText(false);
    } else {
      setArrowColors(
        orientation.beta > 90 ? "red" : "green",
        orientation.beta < 90 ? "red" : "green"
      );
      setShowHelpText(true);
    }
  }, [orientation]);

  const setArrowColors = (upColor: string, downColor: string = "") => {
    setUpArrowColor(upColor);
    setDownArrowColor(downColor === "" ? upColor : downColor)
  }

  const getOverlayClass = () => {
    if (isCorrectOrientation) {
      return "overlay overlay-color green";
    } else {
      if (orientation.beta > 90 || orientation.beta < 90) {
        return "overlay overlay-color red";
      }
    }
    return "overlay";
  };

  const gcd = (a: number, b: number): number => {
    return b === 0 ? a : gcd(b, a % b);
  }

  const handleVideoReady = () => {
    if (cameraRef.current) {
      const video = cameraRef.current.querySelector('video') as HTMLVideoElement;
    console.log("handleVideoReady")
      setVideoElement(video);

      // Remove when debugging is done
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      const clientWidth = video.clientWidth;
      const clientHeight = video.clientHeight;
      // ---

      console.log('Video feed ready.');
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (poses) {
      drawPose(poses);
    }
    
    return () => {
      isMounted = false;
    };
  }, [poses])

  useEffect(() => {
    let isMounted = true;

    const handleMetadataLoaded = () => {
      if (videoElement && isMounted) {
        //console.log("handleMetadataLoaded")
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const clientWidth = videoElement.clientWidth;
        const clientHeight = videoElement.clientHeight;

        setVideoWidth(videoWidth);
        setVideoHeight(videoHeight);

        //console.log("videoWidth:", videoWidth)
        //console.log("clientWidth:", clientWidth)
        //console.log("videoHeight:", videoHeight)
        //console.log("clientHeight:", clientHeight)

        const divisor = gcd(videoWidth, videoHeight);
        const aspectWidth = videoWidth / divisor;
        const aspectHeight = videoHeight / divisor;
        //console.log("Aspect Ratio:", `${aspectWidth}:${aspectHeight}`);
      }
    };

    const handleResize = () => {
      //console.log("handleResize");
      checkIsMobile();
      setupVideo();
    };

    const checkIsMobile = () => {
      //console.log("checkIsMobile")
      setIsMobile(window.innerWidth <= maxWidth);
    };

    const setupVideo = async () => {
      // If there's no video element, or a stream is already assigned, return early
      if (!videoElement || !isMounted) {
        //console.log("setupVideo")
        console.log("videoElement:", videoElement, "isMounted:", isMounted)
        return
      };

     // console.log("setupVideo")
  
      try {
        // If a video stream already exists, stop all its tracks first
        // This is necessary to ensure the old stream is completely cleared before setting a new one
        if (videoElement.srcObject) {
          const tracks = (videoElement.srcObject as MediaStream).getTracks();
          tracks.forEach(track => track.stop());
        }

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

  
        // Request a new video stream with specific constraints
        const constraints: MediaTrackConstraints = {
          deviceId: activeDeviceId ? { exact: activeDeviceId } : undefined,
          //width: isMobile ? { max: windowWidth } : { ideal: windowWidth },
          //height: isMobile ? { max: windowHeight } : { ideal: windowHeight }
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          video: constraints
        });
        console.log("stream:", stream)
  
        // Assign the new stream to the video element and play it
        videoElement.srcObject = stream;
        
  
        // Update video element's size based on the loaded video stream
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        videoElement.width = videoWidth;
        videoElement.height = videoHeight;
        
      } catch (error) {
        console.error('Error setting up video stream:', error);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleMetadataLoaded);
    }
  
    // Cleanup function to stop the video stream when the component unmounts
    return () => {
      isMounted = false;
      if (videoElement && videoElement.srcObject) {
        const tracks = (videoElement.srcObject as MediaStream).getTracks();
        tracks.forEach(track => track.stop());
      }
      window.removeEventListener('resize', handleResize);
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleMetadataLoaded);
      }
    };
  }, [videoElement, activeDeviceId, isMobile]);

  const drawPose = (poses: null | poseDetection.Pose[]) => {
    const canvas = document.getElementById("pose-canvas") as HTMLCanvasElement;
    const canvasContainer = document.querySelector('.pose-info') as HTMLElement;
    if (canvasContainer) {
    //  canvasContainer.style.width = `${videoWidth}px`;
    //  canvasContainer.style.height = `${videoHeight}px`;
        canvasContainer.style.width = "100%";
        canvasContainer.style.height = "100%";
    }

    const minScore = 0.5;

    const videoH = videoSize.height
    const videoW = videoSize.width


    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight
    const scaleY = window.innerHeight/videoH;
    const widthScaled = videoW * scaleY;
    const cropX = (window.innerWidth - widthScaled) / 2;
    const scaleX = scaleY;

    
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx && poses) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        poses.forEach((pose) => {
          pose.keypoints.forEach((keypoint: Record<any, any>) => {
            keypoint.x *= scaleX;
            keypoint.x += cropX;
            keypoint.y *= scaleY;
            if (keypoint.score > minScore) {
              const x = keypoint.x
              const y = keypoint.y
              ctx.beginPath();
              ctx.arc(x, y, 5, 0, 2 * Math.PI);
              ctx.fillStyle = "green";
              ctx.fill();
            }
          });
  
          // Draw skeleton (lines)
          const showLines = false
          if (showLines){
            const adjacentKeyPoints =
            poseDetection.util.getAdjacentPairs(poseDetection.SupportedModels.MoveNet);
          adjacentKeyPoints.forEach(([i, j]) => {
            const kp1 = pose.keypoints[i];
            const kp2 = pose.keypoints[j];
            if ((kp1 && kp2 && kp1.score != null && kp2.score != null) && kp1.score > minScore && kp2.score > minScore) {
              ctx.beginPath();
              ctx.moveTo(kp1.x, kp1.y);
              ctx.lineTo(kp2.x, kp2.y);
              ctx.strokeStyle = "aqua";
              ctx.lineWidth = 2;
              ctx.stroke();
            }
          });}
        });
      }
    }
  };

  const takePhoto = async () => {
    if (camera.current) {
      const photo = camera.current.takePhoto();
      setImage(photo);
      props.setUploaded(true);

      let response = await fetch(
        `${PIPEDB_URL}/task/${props.id}/${props.pose}_img`,
        {
          method: "POST",
          body: JSON.stringify({
            pixels: photo.split(",")[1],
            createdBy: "pro",
          }),
        }
      );
      response = await fetch(
        `${PIPEDB_URL}/task/${props.id}/${props.pose}_angle`,
        {
          method: "POST",
          body: JSON.stringify(
            {"beta": orientation.beta, "alpha": orientation.alpha, "gamma": orientation.gamma}
          ),
        }
      );
    }
  };

  const selectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      const photo = e.target?.result?.toString();
      if (photo) {
        setImage(photo as string);
        let response = await fetch(`${PIPEDB_URL}/task/${props.id}/${props.pose}_img`, {
          method: 'POST',
          body: JSON.stringify({ pixels: photo.split(",")[1], createdBy: 'pro' })
        });
        props.setUploaded(true);

      }
    };
    fileReader.readAsDataURL(event.target.files?.[0] as Blob);

  }
  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      setDevices(videoDevices);
      console.log("videoDevices:", videoDevices)
    })();
  }, []);
  return (
    <Wrapper>
      <div className="webcam-container">
      <VideoPoseContainer width={videoWidth} height={videoHeight} ref={cameraRef}>
        <Camera
        ref={camera}
        facingMode='environment'
        aspectRatio="cover"
        numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
        videoSourceDeviceId={activeDeviceId}
        errorMessages={{
          noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
          permissionDenied: 'Permission denied. Please refresh and give camera permission.',
          switchCamera:
            'It is not possible to switch camera to different one because there is only one video device accessible.',
          canvas: 'Canvas is not supported.',
        }}
        videoReadyCallback={handleVideoReady}
        />
        
        <div className="pose-info">
        <PoseEstimation videoSize={videoSize} setVideoSize={setVideoSize} video={videoElement} />
          <canvas id="pose-canvas" /* width={videoWidth} height={videoHeight} */ />
        </div>
        </VideoPoseContainer>

        <div className="overlay-container" style={{
          width: '100%', justifyContent: 'center', display: 'flex',
          alignItems: 'center', height: "100%", top: "0%", left: "0%"
        }}>
          <img className={getOverlayClass()} src={overlay_image[props.pose+"_"+props.gender]} style={{ zIndex: 1000, maxHeight: "100%", aspectRatio:'preserve', justifyContent: 'center', opacity: '50%' }} />
        </div>

        <div className='motion-info'>
				{<DeviceOrientation />}
        <IonIcon icon={caretUpOutline} className='icon-arrow up' style={{ color: downArrowColor }} />
        <IonIcon icon={caretDownOutline} className='icon-arrow down' style={{ color: upArrowColor }} />
				</div>
        {showHelpText && (
        <div className='help-text'>
        Tilt device until arrows are green
        </div>
        )}
      </div>

      <Control>

        <ChangeFacingCameraButton
          /* disabled={numberOfCameras <= 1} */
          onClick={() => {
            document.getElementById("selectCamera")?.click();
          }}
        />
        <IonSelect hidden placeholder="Select camera" title='Select camera' id="selectCamera"

          onIonChange={(details) => {
            console.log("event.currentTarget.value", details.detail.value);
            setActiveDeviceId(details.detail.value);
          }}  >
          {devices.map((d) => (
            <IonSelectOption key={d.deviceId} value={d.deviceId}>
              {d.label}
            </IonSelectOption>))
          }
        </IonSelect>
        <div>
          <input
            ref={fileInput}
            id='fileInput.upload'
            hidden
            type="file"
            accept="image/jpeg"
            onChange={selectFile}
            onClick={() => { console.log('onClick'); }}
          />
          <UploadButton onClick={() => document.getElementById('fileInput.upload')?.click()}></UploadButton>
          <TakePhotoButton
            disabled={isMobile && !(isCorrectOrientation)}
            onClick={async () => {
              takePhoto();
            }}
          />
        </div>
      </Control>
    </Wrapper>
  );
};

export default CameraOverlay;