import React, { useContext, useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgpu";
import * as poseDetection from "@tensorflow-models/pose-detection";
import { Context } from "../MyContext";

interface PoseEstimationProps {
  video: HTMLVideoElement | null;
  videoSize: VideoSize
  setVideoSize: Function
  
}
export interface VideoSize {
    width: number;
    height: number;
    }

export const PoseEstimation: React.FC<PoseEstimationProps> = ({ video,videoSize, setVideoSize }) => {
  tf.setBackend("webgl");
  const [poses, setLocalPoses] = useState<null | poseDetection.Pose[]>(null);
  const { setPoses: setGlobalPoses } = useContext(Context);
  const estimationConfig = {
    maxPoses: 1,
    flipHorizontal: false,
  };

  const detectorConfig = {
    modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING,
  };
  useEffect(() => {
    let isMounted = true;
    const setupPoseDetection = async () => {
        console.log("pose detection setup")
      await tf.ready();
      const detector = await poseDetection.createDetector(
        poseDetection.SupportedModels.MoveNet,
        detectorConfig
      );
      let hasLogged = false;

      const estimatePoses = async () => {
        if (isMounted && video && video.readyState >= 2 && video.videoWidth > 0 && video.videoHeight > 0) {
          try {
            const poses = await detector.estimatePoses(video, estimationConfig);
            const size: VideoSize = {width: video.videoWidth, height: video.videoHeight};
            if(!videoSize || videoSize.width !== size.width || videoSize.height !== size.height){
                setVideoSize(size);
                console.log("input", video.videoWidth, video.videoHeight)
            }
            if (poses && poses.length > 0) {
              setLocalPoses(poses);
              setGlobalPoses(poses);
            }
          } catch (error) {
            console.error('Error in pose estimation:', error);
          }
        }
          requestAnimationFrame(estimatePoses);
      };
      estimatePoses();
      requestAnimationFrame(estimatePoses);
      
    };
    setupPoseDetection();

    return () => {
      isMounted = false;
    };
  }, [video]);

  return null;
};

export default PoseEstimation;
